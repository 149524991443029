import store from '../data/store';
import { doctorBlack, doctorSecondary, nurseBlack, nurseSecondary } from '@/assets';

export function showSuccessAlert (message) {
	store.commit('alerts/add', { type: 'success', message });
	store.commit('alerts/clear', { type: 'success', message });
}

export function showErrorAlert (message) {
	store.commit('alerts/add', { type: 'error', message });
	store.commit('alerts/clear', { type: 'success', message });
}

/**
 * Returns an JSON object for defining props property in datatable
 *
 * @param  dataTableComponentName  dataTable name from the component what we want to handel the props name
 * @return props object
 * @see         props
 */
export function getDataTableFooterProps (dataTableComponentName) {
	// If you want to handle specific data table add the parameters and name dataTableComponentName  to the switch case
	var tablePropsDefault = {
		'items-per-page-options': [5, 10, 25, 50, -1],
		'items-per-page-text': this.$t('rows-per-page'),
	};
	switch (dataTableComponentName) {
		case 'systemLogsTable': {
			var tableProps = {
				'items-per-page-options': [-1, 100, 500, 1000, 5000],
				'items-per-page-text': this.$t('rows-per-page'),
			};
			return tableProps;
		}
		default: {
			return tablePropsDefault;
		}
	}
}
/**
 * Returns a class for defining odd or even rows in tr in v-data-table
 *
 * @param  dataTableIndex  index from the data table to determine if the rows are even or odd , so we apply a style.
 * @see    class
 */
export function getRowsForDataTable (dataTableIndex) {
	if (dataTableIndex % 2 === 1) {
		return 'odd-row';
	}
	if (dataTableIndex % 2 === 0) {
		return 'even-row';
	}
}
export function getScoresForAssessment (assessment) {
	if (assessment.isCompleted === false) return null;
	const validCategoryIds = [27, 28, 29, 30, 31, 32, 33, 34];
	// Calculate only implemented assessment tests
	if (!validCategoryIds.includes(assessment.mentalHealthCategoryId)) {
		return null;
	}
	// switch (assessment.mentalHealthCategoryId) {
	// 	case 27: // PHQ-9 score calculation
	// 	{
	//
	// 	} break;
	// 	// default:
	// 	// {
	// 	//
	// 	// } break;
	// }
}

export function getDoctorIcon (status) {
		switch (status) {
			case 1:
				return doctorBlack;
			default:
				return doctorSecondary;
		}
}
export function getNurseIcon (status) {
	switch (status) {
		case 1:
			return nurseBlack;
		default:
			return nurseSecondary;
	}
}
export function toggleHeaders () {
	const table = this.$refs.table;
	if (table) {
		const openCache = table?.$vnode.componentInstance.openCache;
		if (openCache) {
			Object.keys(openCache).forEach(group => {
				this.$set(openCache, group, false);
			});
		}
	}
}
export function calculateAge (dateString) {
	var today = new Date();
	var birthDate = new Date(dateString?.split('T')[0]);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
}
